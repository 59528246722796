









































import Reviews from '../reviews/mixins/Reviews'
import mixins from 'vue-typed-mixins'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'

export default mixins(Reviews).extend({
  name: 'FlexibleReviews1',
  components: { DefaultButton },
  data () {
    return {
    }
  }
})
